import { Component, Vue, Watch } from 'vue-property-decorator'
import AgendaDialog from '@/components/shared/Dialogs/AgendaDialog/AgendaDialog.vue'
import { AgendaEvent, MoveAppointmentTimeData, AppointmentResponseModel, AgendaEventResponseModel } from '@/models/agenda-model'
import { soinsTypes, siteItems, calendarColors, calendarColorsInexcuse } from '@/views/Administration/Constants'
import { RoomService } from '@/services/room-service'
import { HourService } from '@/services/hour-service'
import { RoomModel, RoomPlanningModel, RoomPlanningPeriodModel } from '@/models/rooms-model'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { AuthService } from '@/services/auth-service'
import { AbsenceModel, AppUser } from '@/models/app-user-dto'
import { TextValue } from '@/components/shared/Helpers/common-models'
import AgendaHelpers from '@/components/shared/Helpers/agenda-helpers'
import { SoinService } from '@/services/soin-service'
import { SoinResponseModel } from '@/models/soin-model'
import { UserprofileService } from '@/services/user-profile-service'
import { AgendaService } from '@/services/agenda-service'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Commons from '@/components/shared/Helpers/commons'
import CancelAgendaDialog from '@/components/shared/Dialogs/CancelAgendaDialog/CancelAgendaDialog.vue'
import MoveAgendaDialog from '@/components/shared/Dialogs/MoveAgendaDialog/MoveAgendaDialog.vue'
import { LayoutService } from '@/services/layout-service'
import { Subscription } from 'rxjs'
import { PublicHolidayModel } from '@/models/hours-model'
import RoomHelpers from '@/components/shared/Helpers/RoomHelpers'
import { PatientService } from '@/services/patient-service'
import { DateTime } from 'luxon'

@Component({
  components: {
    AgendaDialog,
    Alert,
    CancelAgendaDialog,
    Confirm,
    MoveAgendaDialog
  }
})
export default class Agenda extends Vue {
  private roomService = RoomService.getInstance()
  private userService = AuthService.getInstance()
  private soinService = SoinService.getInstance()
  private absenceService = UserprofileService.getInstance()
  private agendaService = AgendaService.getInstance()
  private layoutService = LayoutService.getInstance()
  private hourService = HourService.getInstance()
  private patientsService = PatientService.getInstance()

  private ready = false
  private readyRooms = false
  private readyInfirmieres = false
  private readySoins = false
  private readyAbsences = false
  private readyPublicHoliday1 = false
  private readyPublicHoliday2 = false
  private readyPublicHoliday3 = false
  public readyAppointments = false
  private readyPlannings = false
  private pendingChange = false
  private pendingChangeStart = null
  private pendingChangeEnd = null
  private isDeleting = false
  public showCancelAppointmentDialog = false
  public showMoveAppointmentDialog = false
  public showContextMenu = false
  public contextMenuX = 0
  public contextMenuY = 0
  private hasReceivedContextMenuEvent = false

  public allInfirmieres: AppUser[] = []

  private startDragEvent: { startTime: string; endTime: string; id: number } = { startTime: '', endTime: '', id: 0 }
  private dragEvent: any = null
  private dragTime: any = null
  private createEvent: any = null
  private createStart: any = null
  private extendOriginal: any = null
  public dialog = false
  public currentDay = new Date()
  public tab = 0
  public tabs = ['Agenda par lieu', 'Agenda par utilisateur']

  public focus = new Date()
  public view = 'category'
  public currentView = 'day'
  public viewToLabel = {
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour'
  }

  public showDeleteConfirm = false
  public selectedEvent: any = null
  public selectedElement = null
  public movingEvent: any = null
  public movingEventTimeData: MoveAppointmentTimeData|null = null

  public selectedOpen = false
  public allEvents: AgendaEvent[] = []
  public events: AgendaEvent[] = []
  public colors = calendarColors
  public colorsInexcuse = calendarColorsInexcuse

  public allSoinTypes = soinsTypes
  public soinTypesSelected: number[] = []
  public soinTypesForSelectedRooms: TextValue[] = []
  public sites = siteItems
  public siteSelected = 1
  public allRooms: RoomModel[] = []
  public roomsForSelectedSite: RoomModel[] = []
  public roomSelected: number[] = []
  public roomSelectedNames: string[] = []
  public userSelected = ''
  public agendaType = 0
  public categoryHideDynamic = true
  public allSoins: SoinResponseModel[] = []

  public allAbsences: AbsenceModel[] = []

  public publicHolidaysForYear: PublicHolidayModel[] = []

  public relevantPlannings: RoomPlanningPeriodModel[] = []

  public deleteErrorMessages: string[] = []

  public categoryDays = 1
  public weekdays = [1, 2, 3, 4, 5]
  public minWidth = 1
  private lastTimeClicked = 0
  private lastClickedEventId = 0
  private monthViewDefaultTime = AgendaHelpers.ExtractHours("12:00", true)

  public selectedAppointment: AppointmentResponseModel = AgendaHelpers.DefaultAppointmentResponseModel()

  private subscription!: Subscription

  private currentYearForPublicHoliday = 0

  private dayFocusDate = ''

  private displayedPeriodStart = ''
  private displayedPeriodEnd = ''

  public alertMessages: string[] = []
  public showAlert = false
  public alertType: 'success' | 'error' | 'warning' = 'success'

  public onTabChanged (e) {
    // e === 0 => lieu
    // e === 1 => utilisateur
    this.agendaType = e
    switch (this.agendaType) {
      case 0: // lieu
        this.categoryHideDynamic = true
        this.restoreLastSite()
        break
      case 1: // utilisateur
        this.categoryHideDynamic = false
        break
    }
    this.refreshView()
    this.filterEvents()
  }

  public onDayFocusChange (e) {
    this.focus = new Date(e)
    this.updateDisplayedPeriod()
  }

  private mapRoomPlanningPeriods () {
    this.relevantPlannings.forEach((roomPlanningPeriod: RoomPlanningPeriodModel) => {
      RoomHelpers.MapRoomPlanningPeriod(this.allInfirmieres, this.allRooms, roomPlanningPeriod)
    })
  }

  private updateReadyState () {
    this.ready = this.readyRooms && this.readyInfirmieres && this.readySoins && this.readyAbsences && this.readyPublicHoliday1 && this.readyPublicHoliday2 && this.readyPublicHoliday3 && this.readyPlannings
    if (this.pendingChange) {
      this.refreshEvents({ start: this.pendingChangeStart, end: this.pendingChangeEnd })
    }
    if (this.ready) {
      this.mapRoomPlanningPeriods()
    }
  }

  @Watch('readyPlannings')
  public readyPlanningsChanged () {
    this.updateReadyState()
  }

  @Watch('readyRooms')
  public readyRoomsChanged () {
    this.updateReadyState()
  }

  @Watch('readyInfirmieres')
  public readyInfirmieresChanged () {
    this.updateReadyState()
  }

  @Watch('readyPatients')
  public readyPatientsChanged () {
    this.updateReadyState()
  }

  @Watch('readySoins')
  public readySoinsChanged () {
    this.updateReadyState()
  }

  @Watch('readyAbsences')
  public readyAbsencesChanged () {
    this.updateReadyState()
  }

  @Watch('readyPublicHoliday1')
  public readyPublicHoliday1Changed () {
    this.updateReadyState()
  }

  @Watch('readyPublicHoliday2')
  public readyPublicHoliday2Changed () {
    this.updateReadyState()
  }

  @Watch('readyPublicHoliday3')
  public readyPublicHoliday3Changed () {
    this.updateReadyState()
  }

  @Watch('focus')
  public onFocusChanged () {
    this.refreshPublicHolidays()
    this.dayFocusDate = this.focus.toISOString().substr(0, 10)
  }

  private saveLastSite () {
    sessionStorage.setItem('agendaLastSiteSelected', JSON.stringify(this.siteSelected))
  }

  private restoreLastSite () {
    const lastSite = sessionStorage.getItem('agendaLastSiteSelected')
    if (lastSite) {
      this.siteSelected = parseInt(lastSite, 10)
      this.onSiteChanged(false)
    }
  }

  mounted () {
    this.layoutService.updateDrawerList([])
    this.subscription = this.agendaService.appointmentSelected$.subscribe(selectedAppointment => {
      this.selectedAppointment = selectedAppointment
    })

    if (this.selectedAppointment.id > 0) {
      if (this.selectedAppointment.siteId) {
        this.siteSelected = this.selectedAppointment.siteId
        this.saveLastSite()
      }
      this.pendingChangeStart = this.selectedAppointment.start.substring(0, 10) as any
      this.pendingChangeEnd = this.selectedAppointment.end.substring(0, 10) as any
      this.pendingChange = true

      this.focus = new Date(this.selectedAppointment.start.substring(0, 10))
    }

    const lastSite = sessionStorage.getItem('agendaLastSiteSelected')
    if (!lastSite) {
      this.saveLastSite()
    } else {
      this.restoreLastSite()
    }

    this.currentYearForPublicHoliday = 0
    this.ready = false
    this.showDeleteConfirm = false
    this.isDeleting = false
    this.showCancelAppointmentDialog = false
    this.updateDisplayedPeriod()
    this.getAllRooms()
    this.getAllInfirmiereUsers()
    this.getAllSoins()
    this.getAllAbsences()
    this.updateTime()
    this.onFocusChanged()
  }

  public getAllRooms () {
    this.readyRooms = false
    this.roomService.getAllActiveRooms().then((rooms) => {
      this.allRooms = rooms
      this.onSiteChanged(false)
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.readyRooms = true
    })
  }

  public getPlannings () {
    this.readyPlannings = false
    this.roomService.getPlanningPeriods(this.displayedPeriodStart, this.displayedPeriodEnd).then((plannings) => {
      this.relevantPlannings = plannings
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.readyPlannings = true
    })
  }

  public getAllInfirmiereUsers () {
    this.readyInfirmieres = false
    this.userService.getAllInfirmiereGroupUsers().then((infirmieres) => {
      this.allInfirmieres = infirmieres
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.readyInfirmieres = true
    })
  }

  public getAllSoins () {
    this.readySoins = false
    this.soinService.getAllSoins().then((soins) => {
      this.allSoins = soins
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.readySoins = true
    })
  }

  public getAllAbsences () {
    this.readyAbsences = false
    this.absenceService.getAllAbsences().then((absences) => {
      this.allAbsences = absences
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.readyAbsences = true
    })
  }

  public refreshPublicHolidays () {
    if (this.currentYearForPublicHoliday <= 0 || this.focus.getFullYear() !== this.currentYearForPublicHoliday) {
      this.currentYearForPublicHoliday = this.focus.getFullYear()
      this.readyPublicHoliday1 = this.readyPublicHoliday2 = this.readyPublicHoliday3 = false
      this.publicHolidaysForYear = []

      // we need to get the public holidays for this year and the previous year and the next year, in case we get on a view where
      // multiple years are displayed, e.g. around december in week view it could happen that we get the end of the december month
      // and the beginning of january for the next year
      this.hourService.getPublicHolidays(this.currentYearForPublicHoliday - 1).then((result) => {
        this.publicHolidaysForYear = this.publicHolidaysForYear.concat(result)
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertErrorMessages(res)
      }).finally(() => {
        this.readyPublicHoliday1 = true
      })
      this.hourService.getPublicHolidays(this.currentYearForPublicHoliday).then((result) => {
        this.publicHolidaysForYear = this.publicHolidaysForYear.concat(result)
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertErrorMessages(res)
      }).finally(() => {
        this.readyPublicHoliday2 = true
      })
      this.hourService.getPublicHolidays(this.currentYearForPublicHoliday + 1).then((result) => {
        this.publicHolidaysForYear = this.publicHolidaysForYear.concat(result)
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertErrorMessages(res)
      }).finally(() => {
        this.readyPublicHoliday3 = true
      })
    }
  }

  private updateTime () {
    setInterval(() => this.cal !== undefined && this.cal !== null ? this.cal.updateTimes() : undefined, 60 * 1000)
  }

  get cal (): any {
    return this.$refs.calendar
  }

  get nowY () {
    return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
  }

  public openAgendaDialog () {
    this.movingEvent = null
    this.movingEventTimeData = null
    this.dialog = true
    this.selectedOpen = false // make sure the bubble is hidden
  }

  public closeAgendaDialog (eventData) {
    this.dialog = false
    if (eventData.warnings && eventData.warnings.length > 0) {
      this.updateAlertWarningMessage(eventData.warnings)
    }
    if (!eventData.event.id) {
      const filtered = this.events.filter(function (value) {
        return value.id && value.id > 0
      })
      this.events = filtered
    }
    this.refreshEvents({ start: { date: this.displayedPeriodStart }, end: { date: this.displayedPeriodEnd } })
  }

  public refreshView () {
    switch (this.currentView) {
      case 'day':
        this.viewByDay(this.focus)
        break
      case 'week':
        this.viewByWeek()
        break
      case 'month':
        this.viewByMonth()
        break
      default:
        throw new Error(`unsupported value for currentView '${this.currentView}'`)
    }
  }

  public viewDay ({ date }) {
    this.focus = new Date(date)
    this.viewByDay(this.focus)
    this.updateDisplayedPeriod()
  }

  public viewByDay (date) {
    this.closeContextMenu()
    this.focus = date
    this.currentView = 'day'
    this.view = this.isAgendaLieu ? 'category' : 'day'
    this.categoryDays = 1
    this.updateDisplayedPeriod()
  }

  public viewByWeek () {
    this.closeContextMenu()
    this.focus = this.lastMonday(this.focus)
    this.currentView = 'week'
    this.view = this.isAgendaLieu ? 'category' : 'week'
    this.categoryDays = 5
    this.updateDisplayedPeriod()
  }

  public viewByMonth () {
    this.closeContextMenu()
    this.currentView = 'month'
    this.view = 'month'
    this.updateDisplayedPeriod()
  }

  private updateDisplayedPeriod () {
    const focusDate = DateTime.fromJSDate(this.focus, { zone: 'local' })

    switch (this.currentView) {
      case 'day':
        this.displayedPeriodStart = this.displayedPeriodEnd = focusDate.toISODate().substring(0, 10)
        break
      case 'week':
        {
          const weekStart = focusDate.startOf('week').toISODate()
          const weekEnd = focusDate.endOf('week').toISODate()
          this.displayedPeriodStart = weekStart
          this.displayedPeriodEnd = weekEnd
        }
        break
      case 'month':
        this.displayedPeriodStart = focusDate.startOf('month').toISODate()
        this.displayedPeriodEnd = focusDate.endOf('month').toISODate()
        break
      default:
        throw new Error(`unsupported value for currentView '${this.currentView}'`)
    }
    this.getPlannings()
  }

  public lastMonday (curDate) {
    curDate = new Date(curDate)
    curDate.setDate(curDate.getDate() - curDate.getDay() + 1)
    return curDate
  }

  public getEventColor (event) {
    return event.color
  }

  public isCancelled (event) {
    return event.statusId === 2 || event.statusId === 3
  }

  public setToday () {
    this.closeContextMenu()
    if (this.currentView === 'week') {
      this.focus = this.lastMonday(new Date())
    } else {
      this.focus = new Date()
    }
    this.updateDisplayedPeriod()
  }

  public prev () {
    this.closeContextMenu()
    const focusDate = DateTime.fromJSDate(this.focus, { zone: 'local' })

    let newFocusDate
    if (this.currentView === 'month') {
      newFocusDate = focusDate.minus({ months: 1 }).startOf('month')
    } else if (this.currentView === 'week') {
      newFocusDate = focusDate.minus({ weeks: 1 })
    } else {
      const offsetDays = focusDate.weekday === 1 ? 3 : 1
      newFocusDate = focusDate.minus({ days: offsetDays })
    }

    this.focus = newFocusDate.toJSDate()
    this.updateDisplayedPeriod()
  }

  public next () {
    this.closeContextMenu()
    const focusDate = DateTime.fromJSDate(this.focus, { zone: 'local' })

    let newFocusDate
    if (this.currentView === 'month') {
      newFocusDate = focusDate.plus({ months: 1 }).startOf('month')
    } else if (this.currentView === 'week') {
      newFocusDate = focusDate.plus({ weeks: 1 })
    } else {
      const offsetDays = focusDate.weekday === 5 ? 3 : 1
      newFocusDate = focusDate.plus({ days: offsetDays })
    }

    this.focus = newFocusDate.toJSDate()
    this.updateDisplayedPeriod()
  }

  private roundTimeToClosest (timeSpan) {
    const d = new Date(timeSpan)
    let hours = 0
    let minutes = 0
    if (isNaN(d.getTime())) {
      const extracted = AgendaHelpers.ExtractHours(timeSpan, true)
      hours = extracted.hours
      minutes = extracted.minutes
    } else {
      hours = d.getHours()
      minutes = d.getMinutes()
    }
    const m = (((minutes + 7.5) / 15 | 0) * 15) % 60
    const h = ((((minutes / 105) + 0.5) | 0) + hours) % 24
    return `${h}:${m}`
  }

  private roundTime (time, down = true) {
    const roundTo = 15 // minutes
    const roundDownTime = roundTo * 60 * 1000

    return down
      ? time - time % roundDownTime
      : time + (roundDownTime - (time % roundDownTime))
  }

  private toTime (tms) {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
  }

  public onSiteChanged (save) {
    this.roomsForSelectedSite = AgendaHelpers.FilterRoomsBySite(this.siteSelected, this.allRooms)
    this.roomSelected = this.roomsForSelectedSite.map((r) => r.id) as number[]
    this.roomSelectedNames = this.roomsForSelectedSite.map((r) => r.name) as string[]
    this.onRoomChanged()
    if (save) {
      this.saveLastSite()
    }
  }

  public agendaDialogSiteChange (e) {
    this.siteSelected = e
    this.onSiteChanged(false)
    // we are filtering the events, so if we were creating an event, it will no longer be displayed, so re-add it
    this.pushSelectedEvent()
  }

  public agendaDialogUserChange (e) {
    if (this.isAgendaUser) {
      this.userSelected = e
      this.selectedEvent.nurseId = e
      this.onUserChanged()
      // we are filtering the events, so if we were creating an event, it will no longer be displayed, so re-add it
      this.pushSelectedEvent()
    }
  }

  public onRoomChanged () {
    this.roomSelectedNames = this.roomsForSelectedSite.filter((r) => this.roomSelected.includes(r.id!)).map((r) => r.name) as string[]

    this.soinTypesForSelectedRooms = AgendaHelpers.FilterSoinTypesByRooms(this.roomsForSelectedSite, this.roomSelected, this.allSoinTypes)
    this.soinTypesSelected = this.soinTypesForSelectedRooms.map((s) => s.value)
    this.filterEvents()
  }

  public onSoinTypeChanged () {
    this.filterEvents()
  }

  public onUserChanged () {
    this.filterEvents()
  }

  private pushSelectedEvent () {
    if (this.selectedEvent && this.selectedEvent.id === 0) {
      this.events.push(this.selectedEvent)
    }
  }

  private captureStartDragEvent (event) {
    this.startDragEvent = { id: event.id, startTime: event.start.toISOString(), endTime: event.end.toISOString() }
  }

  public startDrag ({ event, timed }) {
    if (this.movingEvent) {
      return
    }
    this.captureStartDragEvent(event)
    // This test is needed because if you double click on an empty cell, it will open the dialog and then immediately think it needs to drag the appointment
    // and therefore come here in startDrag, which then sets the selectedEvent to null and then the dialog stays open but nothing works
    if (!this.dialog) {
      this.selectedEvent = null
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    }
  }

  private createNewAppointment (dateStart: Date, category: string) {
    let canCreate = true
    const hasCategory = !!category
    if (hasCategory) {
      canCreate = this.isRoomCategoryOpen(category, dateStart, false) && this.isRoomPlanned(category, dateStart.getDay(), dateStart.toISOString().substr(0, 10))
    } else if (this.isAgendaUser) {
      canCreate = !this.isSelectedNurseAbsentOn(dateStart, false)
    }
    canCreate = canCreate && !this.isDatePublicHoliday(dateStart)
    if (canCreate) {
      this.createEvent = {
        id: 0,
        name: '',
        start: dateStart,
        end: dateStart,
        color: '',
        timed: true,
        category: hasCategory ? category : '',
        userName: '',
        userInitials: '',
        patient: '',
        alert: '',
        nurseId: '',
        site: '',
        appointmentTypeId: 1,
        dossierId: '',
        prestationId: ''
      }
      this.selectedEvent = this.createEvent
      this.events.push(this.createEvent)
    }
  }

  private doStartTime (tms) {
    if (!this.hasDialogOpen()) {
      this.selectedEvent = null
      const mouse = this.toTime(tms)
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent!.start.getTime()
        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        const dateStart = new Date(this.createStart)
        this.createNewAppointment(dateStart, tms.category)
      }
    }
  }

  public startTimeUser (tms) {
    if (this.isAgendaUser) {
      // user calendar does not have category information
      if (this.movingEvent) {
        this.doContextMenuTimeEvent(tms, false)
      } else {
        this.doStartTime(tms)
      }
    }
  }

  public startTimeLieu (tms) {
    if (this.isAgendaLieu) {
      if (this.movingEvent) {
        this.doContextMenuTimeEvent(tms, true)
      } else {
        this.doStartTime(tms)
      }
    }
  }

  public extendBottom (event) {
    this.captureStartDragEvent(event)
    this.createEvent = event
    this.createStart = event.start
    this.extendOriginal = event.end
  }

  public mouseMove (tms) {
    const mouse = this.toTime(tms)
    if (this.dragEvent && this.dragTime !== null) {
      if (this.dragEvent.statusId === 6 || this.isCancelled(this.dragEvent)) {
        return
      }
      const start = this.dragEvent.start.getTime()
      const end = this.dragEvent.end.getTime()
      const duration = end - start
      const newStartTime = mouse - this.dragTime
      const newStart = this.roundTime(newStartTime)
      const newEnd = newStart + duration

      const newDateStart = new Date(newStart)
      const newDateEnd = new Date(newEnd)
      const isPublicHoliday = this.isDatePublicHoliday(newDateStart)
      const isPlanned = this.isRoomPlanned(this.dragEvent.category, newDateStart.getDay(), newDateStart.toISOString().substr(0, 10))
      if (this.isAgendaLieu) {
        if (!isPublicHoliday && isPlanned &&
          this.isRoomCategoryOpen(this.dragEvent.category, newDateStart, false) && this.isRoomCategoryOpen(this.dragEvent.category, newDateEnd, false)) {
          this.dragEvent.start = new Date(newStart)
          this.dragEvent.end = new Date(newEnd)
        }
      } else if (this.isAgendaUser) {
        if (!isPublicHoliday && isPlanned && !this.isSelectedNurseAbsentOn(newDateStart, true) && !this.isSelectedNurseAbsentOn(newDateEnd, true)) {
          this.dragEvent.start = new Date(newStart)
          this.dragEvent.end = new Date(newEnd)
        }
      }
    } else if (this.createEvent && this.createStart !== null) {
      const mouseRounded = this.roundTime(mouse, false)
      const min = Math.min(mouseRounded, this.createStart)
      const max = Math.max(mouseRounded, this.createStart)

      const newDateStart = new Date(min)
      const newDateEnd = new Date(max)
      const isPublicHoliday = this.isDatePublicHoliday(newDateStart)
      const isPlanned = this.isRoomPlanned(this.createEvent.category, newDateStart.getDay(), newDateStart.toISOString().substr(0, 10))
      if (this.isAgendaLieu) {
        if (!isPublicHoliday && isPlanned &&
          this.isRoomCategoryOpen(this.createEvent.category, newDateStart, false) && this.isRoomCategoryOpen(this.createEvent.category, newDateEnd, false)) {
          this.createEvent.start = newDateStart
          this.createEvent.end = newDateEnd
        }
      } else if (this.isAgendaUser) {
        if (!isPublicHoliday && !this.isSelectedNurseAbsentOn(newDateStart, true) && !this.isSelectedNurseAbsentOn(newDateEnd, true)) {
          this.createEvent.start = newDateStart
          this.createEvent.end = newDateEnd
        }
      }
    }
  }

  public endDrag (e) {
    if (this.movingEvent) {
      // do nothing
    } else if (this.createEvent) {
      this.selectedEvent = this.createEvent
      this.openAgendaDialog()
    } else if (this.selectedEvent && !this.dragEvent) {
      this.openAgendaDialog()
    } else if (this.dragEvent) {
      // if the start drag event changed (i.e. if the start or end changed either by dragging the event around, or by extending its bottom)
      if (this.dragEvent.id === this.startDragEvent.id && (this.dragEvent.start.toISOString() !== this.startDragEvent.startTime || this.dragEvent.end.toISOString() !== this.startDragEvent.endTime)) {
        this.movingEventTimeData = {
          date: e.date,
          startTime: Commons.FormatTimeForInputField(this.roundTimeToClosest(this.dragEvent.start)),
          category: this.dragEvent.category
        }
        this.hasReceivedContextMenuEvent = true
        this.movingEvent = this.dragEvent
        this.endMove()
      }
    }
    this.dragTime = null
    this.dragEvent = null
    this.createEvent = null
    this.createStart = null
    this.extendOriginal = null
  }

  public cancelDrag () {
    if (this.createEvent) {
      if (this.extendOriginal) {
        this.createEvent.end = new Date(this.extendOriginal)
      } else {
        const i = this.events.indexOf(this.createEvent)
        if (i !== -1) {
          this.events.splice(i, 1)
        }
      }
    }

    this.createEvent = null
    this.createStart = null
    this.dragTime = null
    this.dragEvent = null
  }

  public endMove () {
    this.showMoveAppointmentDialog = true
  }

  public cancelMove () {
    this.resetMoveAppointment()
  }

  public cancelMoveAnddCreate () {
    let category
    let dateStr = ''
    let date = new Date()
    let tryToCreate = false
    if (this.movingEventTimeData) {
      tryToCreate = true
      category = this.movingEventTimeData.category
      date = new Date(this.movingEventTimeData.date)
      dateStr = this.movingEventTimeData.date
      const extracted = AgendaHelpers.ExtractHours(this.movingEventTimeData.startTime, true)
      date.setHours(extracted.hours)
      date.setMinutes(extracted.minutes)
    }
    this.cancelMove()
    this.cancelDrag()
    this.extendOriginal = null
    if (tryToCreate) {
      this.createNewAppointment(date, category)
      this.endDrag({ date: dateStr })
    }
  }

  public showEvent ({ nativeEvent, event }) {
    if (this.hasDialogOpen()) {
      return
    }
    const now = new Date().getTime()
    const delta = now - this.lastTimeClicked
    this.lastTimeClicked = now
    const sameEvent = event.id === this.lastClickedEventId
    if (delta < 250 && sameEvent) {
      this.selectedOpen = false
      this.selectedEvent = event
      this.openAgendaDialog()
      return
    }
    this.lastClickedEventId = event.id

    const open = () => {
      this.selectedEvent = event
      this.selectedElement = nativeEvent.target
      setTimeout(() => {
        this.selectedOpen = true
      }, 10)
    }

    if (this.selectedOpen) {
      this.selectedOpen = false
      setTimeout(open, 10)
    } else {
      open()
    }

    nativeEvent.stopPropagation()
  }

  public filterEvents () {
    if (this.isAgendaLieu) {
      this.events = this.allEvents.filter((a) => {
        return (this.roomSelected.includes(a.roomId) && this.soinTypesSelected.find((s) => s === a.consultationTypeId)) || a.appointmentTypeId === 2
      })
      this.events.forEach(e => {
        if (e.appointmentTypeId === 2) {
          // in order to make the appointments of type 'other' appear on the calendar:
          // if it's an appointment of type 'other', find the room(s) where the nurse for this appointment is assigned and set that to the appointment
          // but only if it's the right day :)
          for (let i = 1; i <= 5; ++i) {
            if (i === e.start.getDay()) {
              const startStr = e.start.toISOString().substring(0, 10)
              const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, startStr)
              if (planning) {
                const roomPlanning = planning.roomPlannings?.find(r => this.roomsForSelectedSite.some(s => s.id === r.roomId) && r[`day${i}AssignedNurseId`]?.toLowerCase() === e.nurseId.toLowerCase())
                if (roomPlanning) {
                  e.category = roomPlanning.room!
                  break
                } else {
                  e.category = 'Autre'
                }
              }
            }
          }
        }
      })
    } else if (this.isAgendaUser) {
      this.events = this.allEvents.filter((a) => {
        return a.nurseId.toLowerCase() === this.userSelected.toLowerCase()
      })
    } else {
      throw new Error('Unsupported agenda type')
    }
    if (this.movingEvent) {
      // update reference! Otherwise we may be changing view, from day to week for instance, which will trigger a refreshEvents call,
      // which in turn will trigger a call to this filterEvents method, and then we're filtering the events and thus apparently
      // getting new references and so if you move an event from the 'day' view to the 'week' view, when the MoveAgendaDialog is open
      // you will not visually see the event update in the background
      this.movingEvent = this.allEvents.find(e => e.id === this.movingEvent.id)
    }
  }

  public refreshEvents ({ start, end }) {
    if (!this.ready) {
      this.pendingChange = true
      this.pendingChangeStart = start
      this.pendingChangeEnd = end
      return
    }
    this.readyAppointments = false
    this.agendaService.getAppointmentsInRange(start?.date ?? start, end?.date ?? end, this.movingEvent?.id).then((appointments: AgendaEventResponseModel[]) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      const mapped = appointments.map((a) => {
        const nurse = Commons.FindNurse(this.allInfirmieres, a.nurseId)
        const event: AgendaEvent = {
          id: a.id,
          category: a.roomId === 0 ? 'Autre' : that.allRooms.find((r) => r.id === a.roomId)?.name!,
          color: AgendaHelpers.GetEventColor(a.statusId === 6 ? that.colorsInexcuse : that.colors, a.consultationTypeId, a.consultationTypeId === 0 ? 2 : 1, a.isFixed),
          name: a.consultationTypeId === 0 ? 'Autre' : that.soinTypesForSelectedRooms.find((s) => s.value === a.consultationTypeId)?.text!,
          end: new Date(a.end),
          start: new Date(a.start),
          timed: a.timed,
          alert: a.importantInfo,
          userName: nurse?.fullName!,
          userInitials: nurse?.initials!,
          patient: a.patientName ?? '',
          roomId: a.roomId,
          consultationTypeId: a.consultationTypeId,
          nurseId: a.nurseId,
          site: a.roomId === 0 ? '' : that.allRooms.find((r) => r.id === a.roomId)?.site!,
          appointmentTypeId: a.appointmentTypeId,
          statusId: a.statusId,
          description: (a.description ?? '').length > 40 ? `${a.description?.substring(0, 40)}...` : a.description,
          dossierId: a.dossierId,
          prestationId: a.prestationId,
          isFixed: a.isFixed,
          dossierOFASStatusEnumId: a.dossierOFASStatusEnumId
        }
        return event
      })
      this.allEvents = mapped
      this.filterEvents()
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.readyAppointments = true

      if (this.selectedAppointment.id > 0) {
        this.$vuetify.goTo(`#event-${this.selectedAppointment.id}`, { offset: 100 })
        this.agendaService.updateAppointmentSelected(AgendaHelpers.DefaultAppointmentResponseModel())
      }
    })
  }

  get isLoading () {
    return !this.ready || !this.readyAppointments
  }

  public hasAlert (event: AgendaEvent) {
    return event.alert != null && event.alert.length > 0
  }

  public deleteAppointment () {
    if (this.selectedEvent.appointmentTypeId === 2) {
      this.showDeleteConfirm = true
    }
  }

  public async confirmDeleteCallback (value: boolean) {
    if (value) {
      this.isDeleting = true
      const results = await this.agendaService.deleteAppointment(this.selectedEvent.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.deleteErrorMessages = res.errors
        }).finally(() => {
          this.isDeleting = false
        })

      if (results) {
        this.showDeleteConfirm = false
        this.deleteErrorMessages = []
        this.refreshEvents({ start: { date: this.displayedPeriodStart }, end: { date: this.displayedPeriodEnd } })
      }
    } else {
      this.showDeleteConfirm = false
      this.deleteErrorMessages = []
    }
  }

  public onDayClick (tms) {
    if (this.currentView === 'month') {
      if (this.movingEvent) {
        this.doContextMenuTimeEvent(tms, false)
      } else {
        const mouse = this.toTime(tms)
        const start = this.roundTime(mouse)
        const newDate = new Date(start)
        newDate.setHours(this.monthViewDefaultTime.hours)
        let canCreate = !this.isDatePublicHoliday(newDate)
        if (this.isAgendaUser) {
          canCreate = !this.hasSelectedNurseAbsenceInDay(newDate.toISOString().substr(0, 10))
        }
        if (canCreate) {
          this.selectedEvent = {
            id: 0,
            name: '',
            start: newDate,
            end: newDate,
            color: '',
            timed: true,
            category: '',
            userName: '',
            userInitials: '',
            patient: '',
            alert: '',
            nurseId: '',
            dossierId: '',
            prestationId: ''
          }
          this.events.push(this.selectedEvent)
          this.openAgendaDialog()
        }
      }
    }
  }

  public getDeleteConfirmTitle (event) {
    const patientPart = event.patient ? `du patient '${event.patient}'` : ''
    const text = `Supprimer le rendez-vous ${event.name} ${patientPart} avec '${event.userName}' du ${this.displayDate(event.start.toISOString())} à ${event.start.getHours()}:${this.padWithZeros(event.start.getMinutes(), 2)}`
    return text
  }

  public cancelAppointment () {
    this.showCancelAppointmentDialog = true
  }

  public moveAppointment (event) {
    this.selectedOpen = false
    this.movingEvent = event
  }

  public closeContextMenu () {
    this.showContextMenu = false
  }

  public doContextMenuTimeEvent (e, hasCategory: boolean) {
    if (this.currentView === 'month') {
      e.time = `${this.monthViewDefaultTime.hours}:${this.monthViewDefaultTime.minutes}`
    }
    this.movingEventTimeData = {
      date: e.date,
      startTime: Commons.FormatTimeForInputField(this.roundTimeToClosest(e.time)),
      category: hasCategory ? e.category : undefined
    }
    if (this.hasReceivedContextMenuEvent) {
      this.$nextTick(() => {
        this.tryToShowContextMenu()
      })
    }
  }

  public onClickNativeEvent (e) {
    if (this.movingEvent) {
      e.preventDefault()
      this.hasReceivedContextMenuEvent = true
      this.contextMenuX = e.clientX
      this.contextMenuY = e.clientY
      if (this.movingEventTimeData) {
        this.$nextTick(() => {
          this.tryToShowContextMenu()
        })
      }
    }
  }

  private hasDialogOpen () {
    return this.showMoveAppointmentDialog || this.dialog || this.showCancelAppointmentDialog
  }

  private tryToShowContextMenu () {
    if (this.hasReceivedContextMenuEvent && this.movingEventTimeData && !this.hasDialogOpen()) {
      const movingEventDateObject = new Date(this.movingEventTimeData.date)
      const isPublicHoliday = this.isDatePublicHoliday(movingEventDateObject)
      const isPlanned = this.isRoomPlanned(this.movingEventTimeData?.category ?? '', movingEventDateObject.getDay(), this.movingEventTimeData.date)
      if (!isPublicHoliday && isPlanned) {
        let canMove = true
        if (this.currentView === 'month') {
          if (this.isAgendaUser) {
            canMove = !this.hasSelectedNurseAbsenceInDay(this.movingEventTimeData.date)
          }
        } else {
          if (this.movingEventTimeData.category) {
            canMove = this.isRoomCategoryOpenAt(this.movingEventTimeData.category, this.movingEventTimeData.startTime, true)
          } else if (this.isAgendaUser) {
            canMove = !this.isSelectedNurseAbsent(this.movingEventTimeData.date, this.movingEventTimeData.startTime, false)
          }
        }
        this.showContextMenu = canMove
      } else {
        this.showContextMenu = false
      }
    }
  }

  public closeCancelAppointmentDialog () {
    this.showCancelAppointmentDialog = false
    this.refreshEvents({ start: { date: this.displayedPeriodStart }, end: { date: this.displayedPeriodEnd } })
  }

  public closeMoveAppointmentDialog () {
    this.resetMoveAppointment()
    this.refreshEvents({ start: { date: this.displayedPeriodStart }, end: { date: this.displayedPeriodEnd } })
  }

  private resetMoveAppointment () {
    this.movingEvent = null
    this.movingEventTimeData = null
    this.showMoveAppointmentDialog = false
    this.hasReceivedContextMenuEvent = false
  }

  public displayDate (date) {
    return Commons.TransformDateFormat(date)
  }

  public padWithZeros (value, length) {
    return Commons.padWithZeros(value, length)
  }

  get isAgendaLieu () {
    return AgendaHelpers.IsAgendaLieu(this.agendaType)
  }

  get isAgendaUser () {
    return AgendaHelpers.IsAgendaUser(this.agendaType)
  }

  public isRoomCategoryOpenAt (category: string, time: string, isStrict: boolean) {
    const theRoom = this.roomsForSelectedSite.find(r => r.name === category)
    if (theRoom) {
      const extracted = AgendaHelpers.ExtractHours(time, true)
      return this.isRoomOpen(theRoom, `${extracted.hours}:${extracted.minutes}`, isStrict)
    }
    return true
  }

  public isDatePublicHoliday (date: Date) {
    return !!this.publicHolidaysForYear.find(p => {
      const d = new Date(p.date!)
      return d.getFullYear() === date.getFullYear() && d.getMonth() === date.getMonth() && d.getDate() === date.getDate()
    })
  }

  public isRoomCategoryOpen (category: string, date: Date, isStrict: boolean) {
    const theRoom = this.roomsForSelectedSite.find(r => r.name === category)
    if (theRoom) {
      return this.isRoomOpen(theRoom, `${date.getHours()}:${date.getMinutes()}`, isStrict)
    }
    return true
  }

  public isRoomOpen (room: RoomModel, time: string, isStrict: boolean) {
    return AgendaHelpers.IsRoomOpenAt(room, time, isStrict)
  }

  public isRoomDisplayed (room: RoomModel) {
    const checkRooms = this.roomSelected.filter(function (elem) {
      if (elem === room.id) {
        return elem
      }
    })
    return checkRooms.length
  }

  public isSelectedNurseAbsentOn (date: Date, isStrict: boolean) {
    return this.isSelectedNurseAbsent(date.toISOString().substr(0, 10), `${date.getHours()}:${date.getMinutes()}`, isStrict)
  }

  public isSelectedNurseAbsent (date: string, time: string, isStrict: boolean) {
    if (this.userSelected) {
      const theDate = new Date(date)
      const theInfirmiere = Commons.FindNurse(this.allInfirmieres, this.userSelected)
      if (theInfirmiere) {
        const extracted = AgendaHelpers.ExtractHours(time, true)
        theDate.setHours(extracted.hours)
        theDate.setMinutes(extracted.minutes)
        return AgendaHelpers.IsNurseAbsent(theInfirmiere, this.allAbsences, theDate, theDate, isStrict)
      }
    }
    return false
  }

  public hasSelectedNurseAbsenceInDay (date: string) {
    if (this.userSelected) {
      const theDateStart = new Date(date)
      const theDateEnd = new Date(date)
      const theInfirmiere = Commons.FindNurse(this.allInfirmieres, this.userSelected)
      if (theInfirmiere) {
        theDateEnd.setHours(23)
        theDateEnd.setMinutes(59)
        return AgendaHelpers.IsNurseAbsent(theInfirmiere, this.allAbsences, theDateStart, theDateEnd, false)
      }
    }
    return false
  }

  public isRoomCategoryNurseAbsent (category: string, date: string, time: string, isStrict: boolean) {
    const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, date)
    if (planning) {
      const roomPlanning = AgendaHelpers.FindRoomPlanning(planning, this.roomsForSelectedSite, category)
      if (roomPlanning) {
        return this.isRoomNurseAbsent(roomPlanning, date, time, isStrict)
      }
    }
    return false
  }

  public isRoomNurseAbsent (roomPlanning: RoomPlanningModel, date: string, time: string, isStrict: boolean) {
    const dateObj = new Date(date)
    if (!isNaN(dateObj.getTime())) {
      const theInfirmiere = Commons.FindNurse(this.allInfirmieres, roomPlanning[`day${dateObj.getDay()}AssignedNurseId`])
      if (theInfirmiere) {
        const extracted = AgendaHelpers.ExtractHours(time, true)
        dateObj.setHours(extracted.hours)
        dateObj.setMinutes(extracted.minutes)
        return AgendaHelpers.IsNurseAbsent(theInfirmiere, this.allAbsences, dateObj, dateObj, isStrict)
      }
    }
    return false
  }

  public isRoomNurseAbsentOn (category: string, date: Date, isStrict: boolean) {
    const isoDate = date.toISOString().substr(0, 10)
    const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, isoDate)
    if (planning) {
      const roomPlanning = AgendaHelpers.FindRoomPlanning(planning, this.roomsForSelectedSite, category)
      if (roomPlanning) {
        return this.isRoomNurseAbsent(roomPlanning, isoDate, `${date.getHours()}:${date.getMinutes()}`, isStrict)
      }
    }
    return false
  }

  public getNurseForRoom (category: string, weekday: number, date: string) {
    if (category && weekday >= 1 && weekday <= 5) {
      const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, date)
      if (planning) {
        const roomPlanning = AgendaHelpers.FindRoomPlanning(planning, this.roomsForSelectedSite, category)
        if (roomPlanning) {
          const nurse = this.allInfirmieres.find(i => i.id.toLowerCase() === roomPlanning[`day${weekday}AssignedNurseId`]?.toLowerCase())
          if (nurse) {
            return nurse.fullName
          }
        }
      }
    }
    return ''
  }

  public isRoomPlanned (category: string, weekday: number, date: string) {
    if (category && weekday >= 1 && weekday <= 5) {
      const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, date)
      if (planning) {
        const roomPlanning = AgendaHelpers.FindRoomPlanning(planning, this.roomsForSelectedSite, category)
        if (roomPlanning) {
          return !!(this.allInfirmieres.find(i => i.id.toLowerCase() === roomPlanning[`day${weekday}AssignedNurseId`]?.toLowerCase()))
        }
      }
    }
    return false
  }

  public destroyed () {
    this.subscription.unsubscribe()
    this.agendaService.updateAppointmentSelected(AgendaHelpers.DefaultAppointmentResponseModel())
  }

  public hideAlert () {
    this.alertMessages = []
    this.showAlert = false
  }

  public getSoinsForRoom (category: string, date: string, weekday: number) {
    const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, date)
    if (planning) {
      const roomPlanning = AgendaHelpers.FindRoomPlanning(planning, this.roomsForSelectedSite, category)
      if (roomPlanning) {
        const days = ['lu', 'ma', 'me', 'je', 've']
        return RoomHelpers.GetCommonSoinsTypes(roomPlanning.roomSoinsTypeIds ?? [], roomPlanning[days[weekday - 1]]?.types ?? []).map(s => ({ type: this.allSoinTypes.find(t => t.value === s)?.short, name: this.allSoinTypes.find(t => t.value === s)?.text }))
      }
    }
    return []
  }

  public async openPatientTab (dossierId: string) {
    await this.patientsService.updateDossierSelectedByDossierId(dossierId)
    const routeData = this.$router.resolve({ name: 'dossier' })
    window.open(routeData.href, '_blank')
  }

  private updateAlertErrorMessages (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  private updateAlertWarningMessage (message: string[]) {
    this.alertMessages.push(...message)
    this.alertType = 'warning'
    this.showAlert = true
  }

  public isHarcodedFixedBlockedTime (time: string) {
    const hardcodedBlockedTimes = [
      '10:00', '10:15', // 10h-10h30
      '12:00', '12:15', '12:30', '12:45' // 12h-13h
    ]
    if (hardcodedBlockedTimes.some(t => t === time)) {
      return true
    }

    // from 15:15
    const extracted = AgendaHelpers.ExtractHours(time, true)
    return extracted.hours > 15 || (extracted.hours === 15 && extracted.minutes >= 15)
  }

  public extraInfo (event: AgendaEvent) {
    if (this.isAgendaUser && event.site) {
      return ` (${event.site})`
    }
    return ''
  }

  public getSitesFromPlanning (date: string) {
    if (this.isAgendaUser) {
      const targetDate = new Date(date)
      const planning = AgendaHelpers.FindPlanning(this.relevantPlannings, date)
      if (planning) {
        const roomPlanning = planning.roomPlannings?.find(r => r[`day${targetDate.getDay()}AssignedNurseId`]?.toLowerCase() === this.userSelected.toLowerCase())
        if (roomPlanning) {
          return roomPlanning.site
        }
      }
    }
    return ''
  }

  public getSitesFromAppointments (date: string) {
    if (this.isAgendaUser) {
      const targetDate = new Date(date)

      const siteFromPlanning = this.getSitesFromPlanning(date)

      const fromAppointments = [...new Set(this.events.filter(e => e.statusId === 1 && e.start.getDate() === targetDate.getDate() && e.start.getMonth() === targetDate.getMonth() && e.start.getFullYear() === targetDate.getFullYear() && e.site)
        .map(e => e.site))].filter(s => !!s && s !== siteFromPlanning)

      return `${(siteFromPlanning && fromAppointments.length > 0 ? ', ' : '')}${fromAppointments.join(', ')}`
    }
    return ''
  }
}
